module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false,"wrapperStyle":"margin-bottom: 1.0725rem;"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Watchdog Status UI","short_name":"WatchdogStatusUI","description":"","homepage_url":"https://watchdog-status.edgelq.com","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icons":[{"src":"/img/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/img/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/img/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/img/favicon-32x32.png","sizes":"16x16","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/products/watchdog-status-ui/src/i18n","languages":["ja","en"],"defaultLanguage":"ja","redirect":true},
    }]
